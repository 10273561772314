package com.phoenixredwolf.prodweb.pages

import androidx.compose.runtime.Composable
import com.phoenixredwolf.prodweb.components.layouts.PageLayout
import com.phoenixredwolf.prodweb.components.models.Services.UCAAS
import com.phoenixredwolf.prodweb.components.widgets.*
import com.phoenixredwolf.prodweb.theme.*
import com.phoenixredwolf.prodweb.utility.Res
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*

@Page
@Composable
fun Ucaas() {
    val title = "Transform Communication with UCaaS | Unified Communications Services | PhoenixRedwolf Digital Services"
    val description = "Experience seamless communication and collaboration with UCaaS solutions by PhoenixRedwolf Digital Services. Our Unified Communications Services empower your team for success. Explore UCaaS today!"

    PageLayout(title, description) {
        Column(
            modifier = Modifier
                .margin(top = 150.px)
                .padding(bottom = 20.px)
                .fillMaxWidth(),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Box(
                modifier = Modifier.fillMaxWidth()
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.End
                ) {
                    Image(
                        src = Res.Image.ucaaslg,
                        modifier = ServiceImageStyle.toModifier()
                    )
                }
                Column(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalAlignment = Alignment.CenterHorizontally,
                    verticalArrangement = Arrangement.Top
                ) {
                    Row(
                        modifier = Modifier
                            .fillMaxWidth(90.percent),
                        horizontalArrangement = Arrangement.Start
                    ) {
                        H1(
                            attrs = ServiceH1Style.toModifier()
                                .toAttrs(),
                            content = { Text("Unified Communications (UCaaS)") }
                        )
                    }
                    Column(
                        modifier = Modifier
                            .fillMaxWidth(90.percent),
                        horizontalAlignment = Alignment.Start
                    ) {
                        P(
                            attrs = ServicePHeadingStyle.toModifier()
                                .classNames("fs-4")
                                .toAttrs()
                        ) {
                            Text("Our UCaaS empowers businesses with unified communication solutions, combining voice, video, messaging, and collaboration tools. Elevate efficiency, boost teamwork, and enhance customer service with seamless, scalable communication.")
                        }
                        P(
                            attrs = ServicePHeadingStyle.toModifier()
                                .classNames("fs-4")
                                .toAttrs()
                        ) {
                            Text("With our UCaaS solutions, you can experience the power of unified communication, enhancing collaboration, efficiency, and customer service for your business.")
                        }
                        Row(
                            modifier = ServiceCBRowStyle.toModifier(),
                            horizontalArrangement = Arrangement.Center
                        ) {
                            ContactButton()
                        }
                    }
                }
            }
            Column(
                modifier = Modifier
                    .fillMaxWidth(90.percent),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                H3(
                    attrs = ServiceH3Style.toModifier().toAttrs(),
                    content = { Text("Elevate Communication with Unified Communications as a Service") }
                )
                P(
                    attrs = Modifier
                        .classNames("fs-4")
                        .textAlign(TextAlign.Justify)
                        .padding(bottom = 10.px)
                        .toAttrs(),
                    content = {
                        Text("Welcome to PhoenixRedwolf Digital Services, where we revolutionize business communication with our Unified Communications as a Service (UCaaS) solutions. In today's dynamic work environment, seamless communication and collaboration are vital for success. Discover how our UCaaS offerings empower your team to work smarter, not harder.")
                    }
                )
                Benefits(UCAAS)
                ServiceOfferings(UCAAS)
                ScheduleConsult()
                SimpleGrid(
                    modifier = Modifier
                        .margin(top = 10.px, bottom = 200.px)
                        .rowGap(200.px)
                        .fillMaxWidth(),
                    numColumns = numColumns(1, md=2, lg=3, xl = 4)
                ) {
                    UCAAS.cards.forEach { cardInfo ->
                        Div {
                            BottomCards(cardInfo)
                        }
                    }
                }
            }
        }
    }
}