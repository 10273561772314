import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobalsLocal
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlin.Unit
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main(): Unit {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { com.phoenixredwolf.prodweb.pages.HomePage() }
        ctx.router.register("/about") { com.phoenixredwolf.prodweb.pages.AboutPage() }
        ctx.router.register("/branding") { com.phoenixredwolf.prodweb.pages.Branding() }
        ctx.router.register("/cloud") { com.phoenixredwolf.prodweb.pages.Cloud() }
        ctx.router.register("/consult") { com.phoenixredwolf.prodweb.pages.Consult() }
        ctx.router.register("/contact") { com.phoenixredwolf.prodweb.pages.Contact() }
        ctx.router.register("/credits") { com.phoenixredwolf.prodweb.pages.CreditPage() }
        ctx.router.register("/cyber") { com.phoenixredwolf.prodweb.pages.Cyber() }
        ctx.router.register("/innovate") { com.phoenixredwolf.prodweb.pages.Innovate() }
        ctx.router.register("/itconsult") { com.phoenixredwolf.prodweb.pages.ITConsult() }
        ctx.router.register("/itoper") { com.phoenixredwolf.prodweb.pages.ITOper() }
        ctx.router.register("/itsupport") { com.phoenixredwolf.prodweb.pages.ITSupport() }
        ctx.router.register("/managed") { com.phoenixredwolf.prodweb.pages.Managed() }
        ctx.router.register("/markdown") { com.phoenixredwolf.prodweb.pages.MarkdownPage() }
        ctx.router.register("/network") { com.phoenixredwolf.prodweb.pages.Network() }
        ctx.router.register("/possystems") { com.phoenixredwolf.prodweb.pages.PosSystems() }
        ctx.router.register("/privacy") { com.phoenixredwolf.prodweb.pages.Privacy() }
        ctx.router.register("/recruiting") { com.phoenixredwolf.prodweb.pages.Recruiting() }
        ctx.router.register("/risk") { com.phoenixredwolf.prodweb.pages.Risk() }
        ctx.router.register("/service") { com.phoenixredwolf.prodweb.pages.Service() }
        ctx.router.register("/social") { com.phoenixredwolf.prodweb.pages.Social() }
        ctx.router.register("/software") { com.phoenixredwolf.prodweb.pages.Software() }
        ctx.router.register("/terms") { com.phoenixredwolf.prodweb.pages.Terms() }
        ctx.router.register("/ucaas") { com.phoenixredwolf.prodweb.pages.Ucaas() }
        ctx.router.register("/voip") { com.phoenixredwolf.prodweb.pages.Voip() }
        ctx.router.register("/webdev") { com.phoenixredwolf.prodweb.pages.Webdev() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.initSilkHook = { ctx ->
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.layouts.FooterStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.sections.CopyrightStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.sections.GridRowStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.sections.NavHeaderStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.BackToTopButtonStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.ArrowStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.FlipCardStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.ContentStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.FrontStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.BackStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.CardStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.Card_circleStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.H2CircleCardStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.PCircleCardStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.CtaContainerStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.CtaStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.ContactButtonStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.FormButtonStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.FormInputStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.FormLabelsStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.FormCheckLabelStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.CheckBoxStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.ContactFormStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.PContactFormStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.NavButtonStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.radioButtonStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.ScheduleRowStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.ScheduleH3Style)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.ScheduleContactLinkStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.ServiceCardStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.ServiceCard_circleStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.ServiceH2CircleCardStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.ServicePCircleCardStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.ServiceCtaContainerStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.ServiceCtaStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.FacebookLinkStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.components.widgets.LinkedInLinkStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.pages.AboutTitlePStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.pages.AboutBodyPStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.pages.AboutImageStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.pages.AboutSpanStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.pages.ContactColumnStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.pages.IndexH1Style)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.pages.IndexH3Style)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.pages.IndexMainPStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.pages.ServiceButtonStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.pages.ContactPStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.pages.ContactLinkStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.pages.TermsH2Style)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.pages.TermsLinkStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.theme.LinkBaseStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.theme.NavItemStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.theme.NavStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.theme.Dropdowncardstyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.theme.ServicePHeadingStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.theme.ServiceCBRowStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.theme.ServiceH1Style)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.theme.ServiceImageStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.theme.ServiceH3Style)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.theme.ServiceH5Style)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.theme.ServiceBPStyle)
        ctx.theme.registerComponentStyle(com.phoenixredwolf.prodweb.theme.ServiceColumnStyle)
        ctx.theme.registerComponentVariants(com.phoenixredwolf.prodweb.components.sections.NavButtonVariant)
        com.phoenixredwolf.prodweb.initSilk(ctx)
    }

    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    // For SEO, we may bake the contents of a page in at build time. However, we will overwrite them
    // the first time we render this page with their composable, dynamic versions. Think of this as
    // poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    val root = document.getElementById("root")!!
    while (root.firstChild != null) {
        root.removeChild(root.firstChild!!)
    }

    renderComposable(rootElementId = "root") {
        CompositionLocalProvider(
            AppGlobalsLocal provides mapOf("title" to "PhoenixRedwolf Digital Services")
        ) { com.phoenixredwolf.prodweb.MyApp {
              router.renderActivePage { renderWithDeferred { it() } }
        } }
    }
}
