package com.phoenixredwolf.prodweb.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun MarkdownPage() {
    com.phoenixredwolf.prodweb.components.layouts.PageLayout("MARKDOWN","") {
        org.jetbrains.compose.web.dom.H2(attrs = { id("markdown-example") }) {
            org.jetbrains.compose.web.dom.Text("Markdown Example")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("This page is generated from markdown.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Kobweb supports all Markdown features, including")
        }
        org.jetbrains.compose.web.dom.Pre { org.jetbrains.compose.web.dom.Code { org.jetbrains.compose.web.dom.Text("""Multi-line
code blocks
""") } }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("as well as ")
            org.jetbrains.compose.web.dom.Code {
                org.jetbrains.compose.web.dom.Text("inline code")
            }
            org.jetbrains.compose.web.dom.Text(" formatting.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Of course, links are supported. For example:")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("\"Use Kobweb to create rich, dynamic web apps with ease, leveraging ")
            com.varabyte.kobweb.silk.components.navigation.Link("https://kotlinlang.org/", "Kotlin")
            org.jetbrains.compose.web.dom.Text(" and ")
            com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/JetBrains/compose-multiplatform#compose-html", "Compose HTML")
            org.jetbrains.compose.web.dom.Text(".\"")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("You can also embed HTML code directly inside your page. Here, the following will create a badge that links to the Varabyte Discord server:")
        }
        com.varabyte.kobweb.compose.dom.GenericTag("a", "href=\"https://discord.gg/5NZ2GKV5Cs\"") {
            com.varabyte.kobweb.compose.dom.GenericTag("img", "alt=\"Varabyte Discord\" src=\"https://img.shields.io/discord/886036660767305799.svg?label=&logo=discord&logoColor=ffffff&color=7389D8&labelColor=6A7EC2\"")
        }

        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Alternately, you can use Kobweb's custom ")
            org.jetbrains.compose.web.dom.Code {
                org.jetbrains.compose.web.dom.Text("{{{ code }}}")
            }
            org.jetbrains.compose.web.dom.Text(" syntax to call into your project's Kotlin code. You can read more about this feature ")
            com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/varabyte/kobweb#kobweb-call", "in Kobweb's README")
            org.jetbrains.compose.web.dom.Text(".")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("In fact, the following may look like an ordinary link, but it is actually provided by Kotlin code:")
        }
        com.phoenixredwolf.prodweb.components.widgets.GoHomeLink()
    }
}
