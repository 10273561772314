package com.phoenixredwolf.prodweb.theme

import com.phoenixredwolf.prodweb.theme.colorLib.Color
import org.jetbrains.compose.web.css.CSSColorValue

val outerColor = com.varabyte.kobweb.compose.ui.graphics.Color

val md_theme_light_primary = Color(0xFF2117E3)
val md_theme_light_onPrimary = Color(0xFFFFFFFF)
val md_theme_light_primaryContainer = Color(0xFFE1E0FF)
val md_theme_light_onPrimaryContainer = Color(0xFF06006C)
val md_theme_light_secondary = Color(0xFF006C4C)
val md_theme_light_onSecondary = Color(0xFFFFFFFF)
val md_theme_light_secondaryContainer = Color(0xFF78FAC3)
val md_theme_light_onSecondaryContainer = Color(0xFF002114)
val md_theme_light_tertiary = Color(0xFF6D5E00)
val md_theme_light_onTertiary = Color(0xFFFFFFFF)
val md_theme_light_tertiaryContainer = Color(0xFFFCE265)
val md_theme_light_onTertiaryContainer = Color(0xFF211B00)
val md_theme_light_error = Color(0xFFBA1A1A)
val md_theme_light_errorContainer = Color(0xFFFFDAD6)
val md_theme_light_onError = Color(0xFFFFFFFF)
val md_theme_light_onErrorContainer = Color(0xFF410002)
val md_theme_light_background = Color(0xFFFFFBFF)
val md_theme_light_onBackground = Color(0xFF1C1B1F)
val md_theme_light_surface = Color(0xFFFFFBFF)
val md_theme_light_onSurface = Color(0xFF1C1B1F)
val md_theme_light_surfaceVariant = Color(0xFFE4E1EC)
val md_theme_light_onSurfaceVariant = Color(0xFF46464F)
val md_theme_light_outline = Color(0xFF777680)
val md_theme_light_inverseOnSurface = Color(0xFFF3EFF4)
val md_theme_light_inverseSurface = Color(0xFF313034)
val md_theme_light_inversePrimary = Color(0xFFC0C1FF)
val md_theme_light_shadow = Color(0xFF000000)
val md_theme_light_surfaceTint = Color(0xFF3E3EF7)
val md_theme_light_outlineVariant = Color(0xFFC8C5D0)
val md_theme_light_scrim = Color(0xFF000000)

val md_theme_dark_primary = Color(0xFFC0C1FF)
val md_theme_dark_onPrimary = Color(0xFF0E00AA)
val md_theme_dark_primaryContainer = Color(0xFF1F13E2)
val md_theme_dark_onPrimaryContainer = Color(0xFFE1E0FF)
val md_theme_dark_secondary = Color(0xFF59DDA9)
val md_theme_dark_onSecondary = Color(0xFF003826)
val md_theme_dark_secondaryContainer = Color(0xFF005138)
val md_theme_dark_onSecondaryContainer = Color(0xFF78FAC3)
val md_theme_dark_tertiary = Color(0xFFDFC64C)
val md_theme_dark_onTertiary = Color(0xFF393000)
val md_theme_dark_tertiaryContainer = Color(0xFF524600)
val md_theme_dark_onTertiaryContainer = Color(0xFFFCE265)
val md_theme_dark_error = Color(0xFFFFB4AB)
val md_theme_dark_errorContainer = Color(0xFF93000A)
val md_theme_dark_onError = Color(0xFF690005)
val md_theme_dark_onErrorContainer = Color(0xFFFFDAD6)
val md_theme_dark_background = Color(0xFF1C1B1F)
val md_theme_dark_onBackground = Color(0xFFE5E1E6)
val md_theme_dark_surface = Color(0xFF1C1B1F)
val md_theme_dark_onSurface = Color(0xFFE5E1E6)
val md_theme_dark_surfaceVariant = Color(0xFF46464F)
val md_theme_dark_onSurfaceVariant = Color(0xFFC8C5D0)
val md_theme_dark_outline = Color(0xFF918F9A)
val md_theme_dark_inverseOnSurface = Color(0xFF1C1B1F)
val md_theme_dark_inverseSurface = Color(0xFFE5E1E6)
val md_theme_dark_inversePrimary = Color(0xFF3E3EF7)
val md_theme_dark_shadow = Color(0xFF000000)
val md_theme_dark_surfaceTint = Color(0xFFC0C1FF)
val md_theme_dark_outlineVariant = Color(0xFF46464F)
val md_theme_dark_scrim = Color(0xFF000000)

val facebook_blue = Color(0xFF1877F2)
val linkedin_blue = Color(0xFF0072B1)

val primGradStart = Color(0x002117E3)
val primGradEnd = Color(0xFF2117E3)

val seed = Color(0xFF2117E3)

fun Color.toCSS(): CSSColorValue = outerColor.argb(this.alpha, this.red, this.green, this.blue)